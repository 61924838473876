import { configureStore, createSlice, combineReducers } from '@reduxjs/toolkit';
import executeData from '../data/executeData';
import configEnv from '../config';
import loginUser from './loginUserSlice';
import loadIndicatorsSettingsData from '../data/loadIndicatorsSettingsData';
import paymnetHistoryData from '../data/paymentHistoryData';
import coinSlice from './coinSlice';
import exchangesSlice from './exchangesSlice';

const baseSlice = (name, initialState) => {
  return createSlice({
    name,
    initialState
  });
};

const config = baseSlice('config', configEnv);
const execute = baseSlice('execute', executeData);
const loadIndicatorsSettings = baseSlice('loadIndicatorsSettings', loadIndicatorsSettingsData);
const paymentHistory = baseSlice('paymentHistory', paymnetHistoryData); // 임시용. axios 가져오면 제거

//// 지표 검색 관련
export const updateComponentParams = (instanceId, params) => ({
  type: 'UPDATE_COMPONENT_PARAMS',
  payload: { instanceId, params }
});

export const setExchangeParams = (exchanges, params) => ({
  type: 'SET_EXCHANGE_PARAMS',
  payload: { exchanges, params }
});

export const setCandelParams = (candel, params) => ({
  type: 'SET_CANDEL_PARAMS',
  payload: { candel, params }
});

const initialState = {
  exchangeParams: {},
  candelParams: {},
  componentParams: [],
};

const indicatorSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_COMPONENT_PARAMS':
      const existingIndex = state.componentParams.findIndex(item => 
        item.instanceId === action.payload.instanceId);

      if (existingIndex !== -1) { // 삼항연산자 이유: useEffect라서 값 수정 될때마다 state.componentParams 이 수정되기 때문에
        return {
          ...state,
          componentParams: state.componentParams.map((item, index) =>  
            index === existingIndex
              ? { ...item, params: action.payload.params }
              : item
          )
        };
      } else {
        return {
          ...state,
          componentParams: [
            ...state.componentParams,
            {
              instanceId: action.payload.instanceId,
              params: action.payload.params
            }
          ]
        };
      }
    case 'SET_EXCHANGE_PARAMS':
      return {
        ...state,
        exchangeParams: {
          ...state.exchangeParams,
          [action.payload.exchanges]: action.payload.params
        }
      };
    case 'SET_CANDEL_PARAMS':
      return {
        ...state,
        candelParams: {
          ...state.candelParams,
          [action.payload.candel]: action.payload.params
        }
      };
    default:
      return state;
  }
};
/////

export const rootReducer = combineReducers({
  loggedinUser: loginUser.reducer,
  config: config.reducer,
  coin: coinSlice,
  execute: execute.reducer,
  loadIndicatorsSettings: loadIndicatorsSettings.reducer,
  paymentHistory: paymentHistory.reducer,
  indicatorSearch: indicatorSearchReducer,
  exchanges: exchangesSlice.reducer,
})

export default configureStore({
  reducer: rootReducer
})
