import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { useState } from 'react';
import config from '../config';
// import { fetchCoinData } from '../data/coinData';

export const fetchCoinData = createAsyncThunk(
  'coin/fetchCoinData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${config.url}/exchangeSymbols`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const transformData = (data) => {
  return Object.entries(data).map(([baseAsset, exchanges], index) => {
    return {
      baseSymbolId: index + 1,
      baseAsset,
      upbitSymbolName: exchanges.includes('upbit') ? `KRW-${baseAsset}` : '',
      binanceSymbolName: exchanges.includes('binance') ? `${baseAsset}USDT` : '',
      okxSymbolName: exchanges.includes('okx') ? `${baseAsset}-USDT` : '',
      bybitSymbolName: exchanges.includes('bybit') ? `${baseAsset}USDT` : ''
    };
  });
};

export const searchCoins = createAsyncThunk(
  'coin/searchCoins',
  async (searchResult, { rejectWithValue }) => {
    console.log(searchResult)
    const searchUrl = `${config.url}/search`;
    
    try {
      const response = await axios.post(searchUrl, searchResult);
      console.log(response);
      return transformData(response.data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }

    // const testData = {
    //   'BTC': ['binance', 'bybit', 'upbit', 'okx'],
    //   'BCH': ['upbit'],
    //   'SOL': ['upbit'],
    //   'NEAR': ['upbit'],
    //   'STRIKE': ['upbit'],
    //   'EGLD': ['upbit'],
    //   'AAVE': ['upbit'],
    //   'AXS': ['upbit'],
    //   'BSV': ['upbit'],
    //   'ETC': ['upbit'],
    //   'ETH': ['upbit'],
    //   'BTG': ['upbit'],
    //   'AVAX': ['upbit']
    // };
   
  }
);

const coinSlice = createSlice({
  name: 'coin',
  initialState: {
    data: null,
    searchData: null,
    searchResults: null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // 전체 코인
      .addCase(fetchCoinData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCoinData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchCoinData.rejected, (state, action) => {
        state.isLoading = false;
        // 에러 발생시에도 이전 데이터를 유지
        state.error = action.payload;
      })

      // 코인 검색
      .addCase(searchCoins.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(searchCoins.fulfilled, (state, action) => {
        state.isLoading = false;
        state.searchResults = action.payload;
        state.error = null;
      })
      .addCase(searchCoins.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default coinSlice.reducer;
