export const loadIndicatorsSettingsData = [
    {
        id: 1,
        name: "하락대응 지표 모음",
        usedIndicator: "MA/EMA/Bol",
        update: "2021.12.07",
        create: "2020.10.02",
        Autotrading: "비활성",
        delete: ""
    },
    {
        id: 2,
        name: "급등 찾기 세팅",
        usedIndicator: "EMA",
        update: "2021.12.07",
        create: "2020.10.02",
        Autotrading: "비활성",
        delete: ""
    }
];

export default loadIndicatorsSettingsData;