export const executeData = [
    {
        id: 1,
        date: '2020-10-16 12:04:08',
        asset: 'BTC/KRW',
        type: '자동매도',
        order_quantity: '0.00020000 BTC',
        unexecuted_quantity: '0.00020000 BTC',
        order_price: '12,582,000 KRW',
        trigger_price: '9,271,000 KRW',
        state: '취소'
    },
    {
        id: 2,
        date: '2020-10-16 12:04:08',
        asset: 'BTC/KRW',
        type: '자동매수',
        order_quantity: '0.00020000 BTC',
        unexecuted_quantity: '0.00020000 BTC',
        order_price: '12,582,000 KRW',
        trigger_price: '9,271,000 KRW',
        state: '취소'
    }
]

export default executeData;