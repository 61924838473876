export const paymnetHistoryData = [
    {
        id: 0,
        applicationDate: "2024년 1월 1일 15:30:00",
        approvalDate: "2024년 1월 1일 15:30:00",
        trial: "Premium",
        state: 0, // 0, 1, 2: 결제승인, 결제취소, 결제만료
    },
    {
        id: 1,
        applicationDate: "2024년 1월 1일 15:30:00",
        approvalDate: "2024년 1월 1일 15:30:00",
        trial: "Premium",
        state: 1,
    },
    {
        id: 2,
        applicationDate: "2024년 1월 1일 15:30:00",
        approvalDate: "2024년 1월 1일 15:30:00",
        trial: "Premium",
        state: 2,
    },
];

// ./kimpData.jsx 참고해서 만들어주세여

export default paymnetHistoryData;