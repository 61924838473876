import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { 
    persistStore, 
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import { rootReducer } from './reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['loggedinUser', 'userIndicator']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const storePersist = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const store = storePersist;
export const persistor = persistStore(storePersist);
