import { createSlice } from '@reduxjs/toolkit';

const loggedinUser = createSlice({
  name: 'loggedinUser',
  initialState: null,
  reducers: {
    setUser(state, action) {
      return action.payload;
    },
    clearUser(state) {
      return null;
    }
  }
})

export const { setUser, clearUser } = loggedinUser.actions
export default loggedinUser