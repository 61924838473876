import { createSlice } from '@reduxjs/toolkit';

const initialSelectedExchangeIds = JSON.parse(sessionStorage.getItem('selectedExchangeIds') || '[]');

const exchangesSlice = createSlice({
    name: 'exchangesSlice',
    initialState: {
        data: [],
        loading: false,
        error: null,
        selectedExchangeIds: initialSelectedExchangeIds,
    },
    reducers: {
        setExchanges(state, action) {
            state.data = action.payload;
        },
        setSelectedExchangeIds(state, action) {
            state.selectedExchangeIds = action.payload;
            sessionStorage.setItem('selectedExchangeIds', JSON.stringify(action.payload));
        },
    }
})

export const { setExchanges, setSelectedExchangeIds } = exchangesSlice.actions;
export default exchangesSlice;