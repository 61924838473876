import axios from 'axios';
import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import config from '../config';
import { setExchanges } from '../store/exchangesSlice';

export const useExchangeData = () => {
    const url = `${config.url}/exchanges`;
    const dispatch = useDispatch();

    const fetchExchangeData = useCallback(async () => {
        const response = await axios.get(url);
        return response.data
    }, [url])

    // const { data, error, isLoading } = useQuery('exchanges', fetchExchangeData);
    useQuery('exchanges', fetchExchangeData, {
        onSuccess: (data) => {
            dispatch(setExchanges(data));
        },
    });

    // return { data, error, isLoading };
}
